.App {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.button {
    height: 40px;
    width: 120px;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
}

.button:hover {
        background-color: #84c586;
        color: whitesmoke;
        border-color: gray;
        transform: scale(1.05);
}

.form__controls {
    margin: 10px;
    padding: 2px;
}

.form__controls > label {
        margin-right: 50px;
        width: 100px;
        height: 30px;
}

.form__controls > input {
        width: 200px;
        height: 30px;
        border-radius: 5px;
        border: 1px solid gray;
        transition: 0.1s ease-in-out;
}

.form__controls > input:focus {
    outline: 2px solid #84c586;
    outline-offset: 1px;
    transform: scale(1.05);
}

.form__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: auto;
    padding: 10px;
    width: 450px;
}

@media only screen and (max-width: 480px) {
    .form__container {
        width: 290px;
    }

    .form__controls > input {
        width: 150px;
    }
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header, .footer {
    height: 80px; /* Fixed height */
    background-color: #333;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header h1
{
    margin-left:20px;
}

.footer {
    height: 40px; /* Fixed height */
    background-color: #333;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: calc(100vh - 120px); /* Ensure it fills remaining space */
    overflow: hidden;
}

.map-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.map-header {
    background: #007bff;
    color: white;
    padding: 10px;
    text-align: center;
}

.map-layout {
    display: flex;
    flex: 1;
    height: 100%;
}

.sidebar {
    background: #333;
    color: white;
    transition: width 0.3s ease-in-out;
    overflow: hidden;
    width: 250px;
    position: relative;
}

.closed {
    width: 50px;
}

.toggle-btn {
    position: absolute;
    top: 10px;
    right: -25px;
    background: #555;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.main {
    flex: 1;
    padding: 20px;
}

.map-container {
    flex: 1;
    height: 100%;
}
/* Styling the InfoWindow content */
.info-window-content {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 200px; /* Set a max width for the content */
}

    /* Heading inside InfoWindow */
    .info-window-content h4 {
        font-size: 16px;
        margin: 0;
        color: #0066cc;
        margin-bottom: 8px;
    }

    /* Paragraph styling */
    .info-window-content p {
        margin: 5px 0;
        color: #555;
    }

    /* Optional: style labels or items in InfoWindow */
    .info-window-content strong {
        color: #333;
    }

/* Hide only the close button */
.gm-style-iw button[aria-label="Close"] {
    display: none !important;
}

#logOut-container {
    margin-left: auto; /* Pushes it to the right */
    padding-right:20px;
    display: flex;
    justify-content: flex-end; /* Aligns button inside to the right */
}

/*Collapse panel left-right*/
.sidebar {
    background: #333;
    color: white;
    transition: width 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.closed {
    width: 50px;
}

.toggle-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px; /* Moves button fully inside */
    background: #555;
    color: white;
    border: none;
    cursor: pointer;
    z-index: 1000;
    border-radius: 50%;
    font-size: 22px;
    font-weight: bold;
    width: 45px; /* Slightly bigger */
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

/* Keep the button position consistent when collapsed */
.closed .toggle-btn {
    right: 5px;
}
/****************/


